<template>
    <DashboardContainer>
        <Chat/>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../containers/DashboardContainer'
import Chat from '../components/chat/chat'


export default {
    name: 'chat',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')
            }
        }
    },
    components: {
        DashboardContainer,
        Chat,
        
    }
}
</script>